import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { HxSpendService, SpendCategoryFullModel, uiLabel } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { NzTreeNode } from 'ng-zorro-antd/tree';

@Component({
  selector: 'hx-spend-category-select',
  templateUrl: './spend-category-select.component.html',
  styleUrls: ['./spend-category-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxSpendCategorySelectComponent),
    multi: true
  }]
})
export class HxSpendCategorySelectComponent implements OnChanges, ControlValueAccessor {
  @Input() brandId!: number;
  @Output() selectChange = new EventEmitter<NzTreeNode>();

  disabled = false;
  options: SpendCategoryFullModel[] = [];
  nodes: NzTreeNode[] = [];
  expandKeys!: string[];
  selected?: string;

  constructor(
    private spendService: HxSpendService,
    private tr: TranslocoService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['brandId']) {
      this.loadCategories();
      console.log('Category');
    }
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (this.selected) {
      this.selectChange.emit(this.nodes.find(p => p.key === this.selected));
    } else {
      this.selectChange.emit();
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: number): void {
    if (obj) {
      this.selected = String(obj);
    } else {
      this.selected = undefined;
    }
  }

  private async loadCategories() {
    this.nodes = [];
    this.expandKeys = [];
    const {list} = await this.spendService.getSpendCategoryList({limit: 1000, brandId: this.brandId});
    this.options = list;
    const parents = this.options.filter(r => !r.parent);
    const children = this.options.filter(r => r.parent);
    parents.forEach(parent => {
      let parentNode: NzTreeNode = {} as NzTreeNode;
      parentNode.key = parent.id + '';
      parentNode.title = uiLabel(this.tr.getActiveLang(), parent.name) ?? '';

      let otherChildren = children.filter(t => t.parent?.id === parent.id);
      let childList: NzTreeNode[] = [];
      otherChildren.forEach(child => {
        let childNode: NzTreeNode = {} as NzTreeNode;
        childNode.key = child.id + '';
        childNode.title = uiLabel(this.tr.getActiveLang(), child.name) ?? '';
        childNode.isLeaf = true;
        childList.push(childNode);
      });
      parentNode.children = childList;
      this.nodes.push(parentNode);
    });
    if (this.selected){
      const keys: string[] = this.options.filter(r => String(r.id) === this.selected && r.parent).map(r => String(r.parent?.id)) ?? [];
      keys.push(this.selected);
      this.expandKeys = keys;
    }
  }
}
