import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { toHttpParams } from '../utils/globals';
import { PagedList, ProductSplitAvailableShapeModel, ProductSplitDefinitionFullModel, ProductSplitDefinitionModel } from '../interface';
import { ProductShortModel } from '../vn-api';

export interface ProductSplitDefinitionRequest {
  id: number;
  inProductInfoId: number;
  outProductInfoId: number;
  splitQuantity: number;
  shape: string;
  inProductSize: string;
}

@Injectable({
  providedIn: 'root'
})
export class HxProductSplitDefinitionService {
  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getProductSplitDefinitions(params?: {limit?: number; page?: number; inProductInfoId?: number; outProductInfoId?: number}): Observable<PagedList<ProductSplitDefinitionFullModel>> {
    return this.httpClient.get<PagedList<ProductSplitDefinitionFullModel>>(`/api/vanilla/product-split-definitions`, { params: toHttpParams(params, true) });
  }

  getProductSplitDefinitionById(id: number): Observable<ProductSplitDefinitionFullModel> {
    return this.httpClient.get<ProductSplitDefinitionFullModel>(`/api/vanilla/product-split-definitions/${id}`);
  }

  saveProductSplitDefinition(body: Partial<ProductSplitDefinitionRequest>): Observable<ProductSplitDefinitionModel> {
    return this.httpClient.post<ProductSplitDefinitionModel>(`/api/vanilla/product-split-definitions`, body);
  }

  deleteProductSplitDefinitionById(id: number): Observable<ProductSplitDefinitionModel> {
    return this.httpClient.delete<ProductSplitDefinitionModel>(`/api/vanilla/product-split-definitions/${id}`);
  }

  getActiveCart(params?: { storeId?: number }): Observable<ProductShortModel> {
    return this.httpClient.get<ProductShortModel>(`/api/vanilla/product-split-definitions/active-cart`, { params: toHttpParams(params, true) });
  }

  cancelActiveCart(): Observable<void> {
    return this.httpClient.post<void>(`/api/vanilla/product-split-definitions/cancel-cart`, {});
  }

  getAvailableShapes(params?: { productSplitDefinitionId?: number }): Observable<ProductSplitAvailableShapeModel> {
    return this.httpClient.get<ProductSplitAvailableShapeModel>(`/api/vanilla/product-split-definitions/available-shapes`, { params: toHttpParams(params, true) });
  }

  splitProcess(body: {productSplitDefinitionId: number, shapes: string[]}): Observable<void> {
    return this.httpClient.post<void>(`/api/vanilla/product-split-definitions/split`, body);
  }
}
