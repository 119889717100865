import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { format } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from '@adm-web-env/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private updateSubject = new Subject<void>();
  readonly update = this.updateSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private modalService: NzModalService,
    private notificationService: NzNotificationService,
    private updates: SwUpdate,
  ) {
  }

  about() {
    this.getLatestVersion().subscribe(appVersion => {
      let version = appVersion || 0;
      if (version === 0) {
        version = Date.now();
      }
      this.modalService.info({
        nzTitle: 'О приложении "adm"',
        nzContent: `Версия ${format(version, 'dd.MM.yyyy HH:mm')}`
      });
    });
  }

  updateApp() {
    this.updates.activateUpdate().then(() => {
      // @ts-ignore
      document.location.reload();
    });
  }

  startUpdateChecker() {
    if (!this.updates.isEnabled) {
      console.warn('sw-update is not enabled');
      return;
    }

    this.updates.versionUpdates.subscribe((event: VersionEvent) => {
      console.log('[app] version update event', event);
      switch (event.type) {
        case 'NO_NEW_VERSION_DETECTED': {
          break;
        }
        case 'VERSION_DETECTED': {
          break;
        }
        case 'VERSION_INSTALLATION_FAILED': {
          break;
        }
        case 'VERSION_READY': {
          this.updateSubject.next();
          this.modalService.confirm({
            nzTitle: 'Обновление приложения',
            nzContent: `Нажмите ОК для перезагрузки страницы`,
            nzOnOk: () => this.updateApp()
          });
          break;
        }
      }
    });
    setTimeout(() => {
      this.updates.checkForUpdate();
      setInterval(() => {
        console.log('checking for new ng version');
        this.updates.checkForUpdate();
      }, 60000);
    }, 2000);
  }

  async checkUpdates() {
    if (!this.updates.isEnabled) {
      console.warn('sw-update is not enabled');
      return Promise.reject('service worker is disabled');
    }
    await this.updates.checkForUpdate();
    this.notificationService.success('Приложение', `Запрошено обновление`);
  }

  getLatestVersion(): Observable<number | undefined> {
    if (!environment.production) {
      return of(0);
    }
    return this.httpClient.get<{ timestamp: number }>('ngsw.json?ngsw-cache-bust=' + Math.random())
      .pipe(map(ngsw => ngsw.timestamp));
  }
}
