import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { HxProductInfoService, ProductInfoModel, ProductInfoType } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Component search product info by search text
 */
@Component({
  selector: 'hx-product-info-select',
  templateUrl: './product-info-select.component.html',
  styleUrls: ['./product-info-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxProductInfoSelectComponent),
    multi: true
  }]
})
export class HxProductInfoSelectComponent implements ControlValueAccessor, OnInit {

  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Input() types: ProductInfoType[] = [];
  @Input() globalForSale?: boolean;
  @Output() selectChange = new EventEmitter<ProductInfoModel | ProductInfoModel[] | undefined>();
  options: ProductInfoModel[] = [];
  isLoading = false;
  private $searchChange = new Subject<string>();
  private $destroyed = new Subject<void>();
  private $selected?: number | number[];

  get selected(): number | number[] | undefined {
    return this.$selected;
  }

  set selected(val: number | number[] | undefined) {
    this.$selected = val;
    this.onChange(this.$selected);
  }

  constructor(
    private productInfoService: HxProductInfoService,
  ) {
  }

  ngOnInit(): void {
    this.$searchChange
      .pipe(
        takeUntil(this.$destroyed),
        filter(query => !!query && query.trim() !== ''),
        debounceTime(500),
        switchMap(query => {
          this.isLoading = true;
          return this.productInfoService.getProductInfos({
            types: this.types,
            limit: 100,
            searchText: query,
            globalForSale: this.globalForSale
          });
        }),
        tap(() => this.isLoading = false),
        map(items => items.list),
      )
      .subscribe(pi => {
        this.isLoading = false;
        this.options = pi;
      });
  }

  onModelChanged(val: any) {
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    if (obj) {
      let ids: number[] = [];
      if (Array.isArray(obj)) {
        ids = ids.concat(obj);
      } else {
        ids.push(obj);
      }
      if (ids.length > 0) {
        this.productInfoService.getProductInfoByIds(ids).subscribe(pi => this.options = pi);
      }
    }
    this.selected = obj;
  }

  search(query: string) {
    this.$searchChange.next(query);
  }
}
