/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WebkassaCashboxStatus = 'CREATED' | 'ACTIVE' | 'OFD_BLOCKED' | 'DATA_FAILURE' | 'TAX_INSPECTOR_BLOCKED' | 'INACTIVE';

export const WebkassaCashboxStatus = {
    CREATED: 'CREATED' as WebkassaCashboxStatus,
    ACTIVE: 'ACTIVE' as WebkassaCashboxStatus,
    OFD_BLOCKED: 'OFD_BLOCKED' as WebkassaCashboxStatus,
    DATA_FAILURE: 'DATA_FAILURE' as WebkassaCashboxStatus,
    TAX_INSPECTOR_BLOCKED: 'TAX_INSPECTOR_BLOCKED' as WebkassaCashboxStatus,
    INACTIVE: 'INACTIVE' as WebkassaCashboxStatus
};

