import { Pipe, PipeTransform } from '@angular/core';

/**
 * Create shortname from FullName
 *
 * @example Uspanov Talgat Bolatovich => Uspanov T.B.
 */
@Pipe({
  name: 'fio'
})
export class HxFioPipe implements PipeTransform {

  transform(value?: string, args?: any): string {
    if (!value) {
      return '';
    }
    return value
      .split(' ')
      .map((item, index) => item && item !== 'null' ? index === 0 ? item : `${item[0].toUpperCase()}.` : '')
      .join(' ');
  }
}
