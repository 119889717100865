import { Component, forwardRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormRecord, NG_VALUE_ACCESSOR } from '@angular/forms';
import { domainLocaleMap, uiLabel, UiLabel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { Subject } from 'rxjs';

type langKey = keyof UiLabel;

@Component({
  selector: 'hx-label-edit',
  templateUrl: './label-edit.component.html',
  styleUrls: ['./label-edit.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxLabelEditComponent),
    multi: true
  }]
})
export class HxLabelEditComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('drawerTpl', {static: false}) drawerTpl?: TemplateRef<{
    $implicit: { value?: UiLabel };
    drawerRef: NzDrawerRef<string>;
  }>;
  @ViewChild('drawerFooterTpl', {static: false}) drawerFooterTpl?: TemplateRef<{}>;
  @Input() type: 'input' | 'textarea' | 'markdown' = 'input';

  label?: string;
  isDisabled = false;
  langs = Array.from(domainLocaleMap.keys());
  form = new FormRecord<FormControl<string|null>>({});
  selected: UiLabel | undefined;
  private $destroyed = new Subject<void>();
  private drawerRef?: NzDrawerRef;

  constructor(
    private tr: TranslocoService,
    private nzDrawerService: NzDrawerService,
  ) {
  }

  ngOnInit(): void {
    this.langs.forEach(lang => this.form.addControl(lang, new FormControl<string | null>(null)));
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(val: any): void {
    this.selected = val;

    if (this.selected) {
      this.updateLabel(this.selected);
      this.form.patchValue({...this.selected});
    } else {
      this.label = undefined;
    }
  }

  showEditPanel() {
    const width = window.innerWidth;
    let drawerWidth = '50%';
    if (width < 800) {
      drawerWidth = '100%';
    }
    this.drawerRef = this.nzDrawerService.create({
      nzTitle: 'Редактирование',
      nzContent: this.drawerTpl,
      nzFooter: this.drawerFooterTpl,
      nzWidth: drawerWidth,
      nzContentParams: {
        value: this.selected
      }
    });
  }

  closeDrawer() {
    const value: UiLabel = this.form.value;
    if (Object.values(value).some(val => val?.trim() !== undefined && val?.trim() !== null)) {
      this.selected = value;
    } else {
      this.selected = undefined;
    }
    this.updateLabel(this.selected);
    this.onChange(value);
    this.drawerRef?.close(this.selected);
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  private updateLabel(val?: UiLabel) {
    if (!val) {
      this.label = undefined;
      return;
    }
    const langs = Object.entries(val).filter(([key, value]) => !!value).map(([key, value]) => key).join(', ');
    let labelVal = uiLabel(this.tr.getActiveLang(), this.selected);
    if (this.type === 'input') {
      labelVal += ` (${langs})`;
    }
    this.label = labelVal;
  }
}
