import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { PagedList, RecipeInfoModel, RecipeModel, WorkflowModel, WorkflowStepModel } from '../interface';
import { toHttpParams } from '../utils/globals';
import { UiLabel, UnitOfMeasureModel } from '../vn-api';
import { RecipeType } from '../enum';

@Injectable({
  providedIn: 'root'
})
export class HxRecipeService {

  constructor(
    private _http: HttpClient,
  ) { }

  // workflow
  getWorkflowList(params?: {
    page?: number;
    limit?: number;
    orderBy?: string;
    workflowCategoryId?: number;
    query?: string;
    brandId?: number;
    storeId?: number;
  }): Observable<PagedList<WorkflowModel>> {
    return this._http.get<PagedList<WorkflowModel>>(`/api/vanilla/workflows`, {params: toHttpParams(params, true)});
  }

  getWorkflowById(workflowId: number): Observable<WorkflowModel> {
    return this._http.get<WorkflowModel>(`/api/vanilla/workflows/${workflowId}`);
  }

  saveWorkflow(body: WorkflowRequest): Observable<WorkflowModel> {
    return this._http.post<WorkflowModel>(`/api/vanilla/workflows`, body);
  }

  removeWorkflow(workflowId: number): Observable<WorkflowModel> {
    return this._http.delete<WorkflowModel>(`/api/vanilla/workflows/${workflowId}`);
  }

  // workflow steps
  getWorkflowStepList(workflowId: number, params?: {
    page?: number;
    limit?: number;
    query?: string;
  }): Observable<PagedList<WorkflowStepModel>> {
    return this._http.get<PagedList<WorkflowStepModel>>(`/api/vanilla/workflows/${workflowId}/steps`, {params: toHttpParams(params, true)});
  }

  getWorkflowStepById(workflowId: number, workflowStepId: number): Observable<WorkflowStepModel> {
    return this._http.get<WorkflowStepModel>(`/api/vanilla/workflows/${workflowId}/steps/${workflowStepId}`);
  }

  saveWorkflowStep(workflowId: number, body: WorkflowStepRequest): Observable<WorkflowStepModel> {
    return this._http.post<WorkflowStepModel>(`/api/vanilla/workflows/${workflowId}/steps`, body);
  }

  removeWorkflowStep(workflowId: number, workflowStepId: number): Observable<WorkflowStepModel> {
    return this._http.delete<WorkflowStepModel>(`/api/vanilla/workflows/${workflowId}/steps/${workflowStepId}`);
  }

  // recipe
  getProductRecipeList(productInfoId: number): Observable<PagedList<RecipeInfoModel>> {
    return this._http.get<PagedList<RecipeInfoModel>>(`/api/vanilla/product-infos/recipes?limit=50&productInfoId=${productInfoId}`);
  }

  updateProductRecipe(recipeId: number, body: any): Observable<any> {
    return this._http.put(`api/vanilla/product-infos/recipes/${recipeId}`, body);
  }

  deleteProductRecipe(recipeId: number): Observable<any> {
    return this._http.delete(`/api/vanilla/product-infos/recipes/${recipeId}`);
  }

  getRecipeList(params: Params): Observable<PagedList<RecipeInfoModel>> {
    return this._http.get<PagedList<RecipeInfoModel>>(`/api/vanilla/recipes`, { params: params });
  }

  getRecipeById(recipeId: number): Observable<RecipeModel> {
    return this._http.get<RecipeModel>(`/api/vanilla/recipes/${recipeId}`);
  }

  saveRecipe(stepId: number, body: RecipeRequest): Observable<RecipeModel> {
    return this._http.post<RecipeModel>(`/api/vanilla/recipes?stepId=${stepId}`, body);
  }

  deleteRecipe(recipeId: number): Observable<RecipeModel> {
    return this._http.delete<RecipeModel>(`/api/vanilla/recipes/${recipeId}`);
  }

  getUnit(unitId: number): Observable<UnitOfMeasureModel> {
    return this._http.get<UnitOfMeasureModel>(`/api/vanilla/unit-of-measures/${unitId}`);
  }
}

export interface WorkflowStepResponse {
  id: number;
  name: string;
  description: string;
  workflow: WorkflowResponse;
  order: number;
  servicePrice: number;
}

export interface WorkflowResponse {
  id: number;
  name: string;
  description: string;
  workflowCategoryId: number;
}

export interface WorkflowStepRequest {
  id: number;
  title: UiLabel;
  description: string;
  order: number;
  servicePrice: number;
}

export interface WorkflowRequest {
  id: number;
  title: UiLabel;
  description: string;
  workflowCategoryId: number;
  storeIds: number[];
}

export interface RecipeRequest {
  id: number;
  productInfoId: number;
  netAmount: number;
  grossAmount: number;
  productUnitOfMeasure: number;
  group: number;
  priority: number;
  coefficient: number;
  toUnitOfMeasure: number;
  type: RecipeType;
}
