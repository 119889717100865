import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import { ComponentType, FaqModel, FaqResourceService, FaqResponse, PagedFaqModel, PagedFaqResponse, SaveFaqRequest } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxFaqService {

  constructor(
    private faq: FaqResourceService
  ) {
  }

  getFaqsWithReactions(params: { limit?: number; page?: number; query?: string; component?: ComponentType; brandId?: number }): Promise<PagedFaqResponse> {
    return firstValueFrom(this.faq.getFaqsWithReactions(params.brandId, params.component, params.limit, params.page, params.query));
  }

  getFaqs(params: { limit?: number; page?: number; query?: string; component?: ComponentType; brandId?: number }): Promise<PagedFaqModel> {
    return firstValueFrom(this.faq.getFaqs(params.brandId, params.component, params.limit, params.page, params.query));
  }

  getFaqById(id: number): Promise<FaqModel> {
    return firstValueFrom(this.faq.getFaqById(id));
  }

  saveFaq(request: SaveFaqRequest): Promise<FaqModel> {
    return firstValueFrom(this.faq.saveFaq(request));
  }

  removeFaq(id: number): Promise<void> {
    return firstValueFrom(this.faq.removeFaq(id));
  }
}
