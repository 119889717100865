/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Currency = 'KZT' | 'USD' | 'RUB' | 'KGS' | 'UZS';

export const Currency = {
    KZT: 'KZT' as Currency,
    USD: 'USD' as Currency,
    RUB: 'RUB' as Currency,
    KGS: 'KGS' as Currency,
    UZS: 'UZS' as Currency
};

