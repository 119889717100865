import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { HxUnitOfMeasureService, UnitOfMeasureModel } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';

/**
 * Component search product info by search text
 */
@Component({
  selector: 'hx-unit-measure-select',
  templateUrl: './unit-measure-select.component.html',
  styleUrls: ['./unit-measure-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxUnitMeasureSelectComponent),
    multi: true
  }]
})
export class HxUnitMeasureSelectComponent implements OnInit, ControlValueAccessor {

  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Input() includeDisabled = true;
  @Output() selectChange = new EventEmitter<UnitOfMeasureModel | UnitOfMeasureModel[] | undefined>();

  options: UnitOfMeasureModel[] = [];
  private selectedVal?: number | number[];

  get selected(): number | number[] | undefined {
    return this.selectedVal;
  }

  set selected(val: number | number[] | undefined) {
    this.selectedVal = val;
    this.onChange(this.selectedVal);
  }

  constructor(
    private uomService: HxUnitOfMeasureService,
  ) {
  }

  ngOnInit(): void {
    this.uomService.getUnitOfMeasures({limit: 1000}).subscribe(res => {
      this.options = res.list;
    });
  }

  onModelChanged(val: any) {
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
