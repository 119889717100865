<nz-layout>
  <nz-sider class="menu-sidebar" [nzTheme]="theme" nzCollapsible nzWidth="256px" [nzCollapsedWidth]="0" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed">
    <div class="sidebar-logo">
      <a href="https://adm.happytech.app/" target="_blank">
        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">
        <h1>{{'app.name' | transloco}}</h1>
      </a>
    </div>
    <ul nz-menu [nzTheme]="theme" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzOpen nzTitle="Продукты" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/composite-products">
              <span>{{'nav.CompositeProducts' | transloco}}</span>
            </a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/product-definitions">{{'nav.ProductDefinitions' | transloco}}</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/product-categories">{{'nav.ProductCategories' | transloco}}</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzOpen nzTitle="Расходы" nzIcon="audit">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/spend-categories"><span>{{'nav.SpendCategories' | transloco}}</span></a>
          </li>
        </ul>
      </li>
      <li nz-menu-item routerLink="dashboard" nzMatchRouter>
        <i nz-icon nzType="file"></i>
        <span>{{'nav.Dashboard' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="cities" nzMatchRouter>
        <i nz-icon nzType="block" nzTheme="outline"></i>
        <span>{{'nav.Cities' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="delivery-zones" nzMatchRouter>
        <i nz-icon nzType="block" nzTheme="outline"></i>
        <span>{{'nav.DeliveryZones' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="organizations" nzMatchRouter>
        <i nz-icon nzType="group" nzTheme="outline"></i>
        <span>{{'nav.Organizations' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="stores" nzMatchRouter>
        <i nz-icon nzType="shop" nzTheme="outline"></i>
        <span>{{'nav.Stores' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="permissions" nzMatchRouter>
        <i nz-icon nzType="file"></i>
        <span>{{'nav.Permissions' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="promo-banners" nzMatchRouter>
        <i nz-icon nzType="percentage" nzTheme="outline"></i>
        <span>{{'nav.PromoBanners' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="webkassa-cashboxes" nzMatchRouter>
        <i nz-icon nzType="desktop" nzTheme="outline"></i>
        <span>{{'nav.WebkassaCashboxes' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="brands" nzMatchRouter>
        <i nz-icon nzType="snippets" nzTheme="outline"></i>
        <span>{{'nav.Brands' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="workflows" nzMatchRouter>
        <i nz-icon nzType="fork" nzTheme="outline"></i>
        <span>{{'nav.Workflows' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="operations" nzMatchRouter>
        <i nz-icon nzType="partition" nzTheme="outline"></i>
        <span>{{'nav.Operations' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="faqs" nzMatchRouter>
        <i nz-icon nzType="bulb" nzTheme="outline"></i>
        <span>{{'nav.Faqs' | transloco}}</span>
      </li>
      <li nz-menu-item routerLink="dictionaries" nzMatchRouter>
        <span nz-icon nzType="bars" nzTheme="outline"></span>
        <span>{{'nav.Dictionaries' | transloco}}</span>
      </li>
    </ul>
  </nz-sider>
  <nz-layout class="main-layout" [ngClass]="{'main-layout__collapsed': isCollapsed}">
    <nz-header>
      <div class="app-header" nz-row nzJustify="space-between">
        <span nz-col [nzSm]="1" class="header-trigger" (click)="isCollapsed = !isCollapsed">
          <i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
        </span>
        <span nz-col [nzSm]="15">
          <nz-tag *ngIf="!isProd" [nzColor]="'volcano'">Тестовый сервер!</nz-tag>
        </span>
        <span nz-col *ngIf="hasAppUpdate" nz-tooltip nzTooltipTitle="Нажмите для обновления приложения" [nzSm]="1" class="header-trigger" (click)="updateApp()">
          <i class="cp__update-app" nz-icon nzType="thunderbolt" nzTheme="twotone"></i>
        </span>
        <span nz-col class="header-trigger">
          <nz-button-group>
            <a nz-button href="#">{{auth.user.firstname}}</a>
            <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
              <i nz-icon nzType="user"></i>
            </button>
          </nz-button-group>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item>
                <a (click)="checkForUpdate()">Проверить обновление</a>
              </li>
              <li nz-menu-item>
                <a (click)="aboutApp()">О приложении</a>
              </li>
              <li nz-menu-item>
                <a (click)="auth.logout()">
                  <i nz-icon nzType="logout"></i> Выход
                </a>
              </li>
            </ul>
          </nz-dropdown-menu>
        </span>
      </div>
    </nz-header>
    <nz-content class="hx-content">
      <div class="inner-content" [hidden]="loading">
        <router-outlet></router-outlet>
      </div>
      <div [hidden]="!loading">
        <nz-skeleton [nzParagraph]="{ rows: 16 }" [nzTitle]="true" [nzLoading]="loading" [nzActive]="loading">
        </nz-skeleton>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
