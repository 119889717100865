import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { HxUserService, UserBasicModel } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NzSelectModeType } from 'ng-zorro-antd/select';

/**
 * Component search product info by search text
 */
@Component({
  selector: 'hx-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxUserSelectComponent),
    multi: true
  }]
})
export class HxUserSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Input() includeDisabled = true;
  @Input() storeId?: number;
  @Output() selectChange = new EventEmitter<UserBasicModel | UserBasicModel[] | undefined>();

  options: UserBasicModel[] = [];
  isLoading = false;
  private selectedVal?: number | number[];
  private $searchChange = new Subject<string>();
  private $destroyed = new Subject<void>();

  get selected(): number | number[] | undefined {
    return this.selectedVal;
  }

  set selected(val: number | number[] | undefined) {
    this.selectedVal = val;
    this.onChange(this.selectedVal);
  }

  constructor(
    private userService: HxUserService,
  ) {
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnInit(): void {
    this.$searchChange
      .pipe(
        takeUntil(this.$destroyed),
        filter(query => !!query && query.trim() !== ''),
        debounceTime(500),
        switchMap(query => {
          this.isLoading = true;
          return this.userService.getUsers({
            query: query,
            storeId: this.storeId,
            includeDisabled: this.includeDisabled,
          });
        }),
        tap(() => this.isLoading = false),
        map(items => items.list),
        catchError(() => of([] as UserBasicModel[])),
      )
      .subscribe(users => {
        this.isLoading = false;
        this.options = users;
      });
  }

  onModelChanged(val: any) {
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    /*console.log('[HxUserSelect] writeValue called', obj);*/
    if (obj) {
      let ids: number[] = [];
      if (Array.isArray(obj)) {
        ids = ids.concat(obj);
      } else {
        ids.push(obj);
      }
      if (ids.length > 0) {
        this.userService.getUserByIds(ids).subscribe(users => this.options = users.list);
      }
    }
    this.selected = obj;
  }

  onSearch(query: string) {
    this.$searchChange.next(query);
  }
}
