import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { GroupModel, HxGroupService, uiLabel } from 'hx-services';
import { TranslocoService } from '@ngneat/transloco';

interface GroupInfoModel extends GroupModel {
  concatTitle: string | null;
  icon: string;
}

@Component({
  selector: 'hx-group-select',
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxGroupSelectComponent),
    multi: true
  }]
})
export class HxGroupSelectComponent implements ControlValueAccessor {
  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Output() selectChange = new EventEmitter<GroupModel | GroupModel[] | undefined>();

  options: GroupInfoModel[] = [];
  private $selected?: number | number[];

  get selected(): number | number[] | undefined {
    return this.$selected;
  }

  set selected(val: number | number[] | undefined) {
    this.$selected = val;
    this.onChange(this.$selected);
  }

  constructor(
    private groupService: HxGroupService,
    private tr: TranslocoService,
  ) {
  }

  search(value: string) {
    if (value && value.length > 2) {
      this.load([], value);
    }
  }

  onModelChanged(val: any) {
    if (this.hxMode === 'default') {
      if (this.selected) {
        // @ts-ignore
        this.load(this.selected ?? [], undefined);
        this.selectChange.emit(this.options);
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.load(ids ?? [], undefined);
        this.selectChange.emit(this.options);
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
    this.onModelChanged(this.selected);
  }

  private load(ids?: number[] | undefined, value?:string) {
    this.groupService.getGroups({ limit: 1000, query: value, ids: ids }).subscribe(result => {
      this.options = result.list.map(r => {
        const info = r as GroupInfoModel;
        if (info.discriminator === 'G') {
          info.icon = 'group';
        } else if (info.discriminator === 'S') {
          info.icon = 'shop';
        } else if (info.discriminator === 'D') {
          info.icon = 'bank';
        }
        if (r.parent?.title) {
          info.concatTitle = uiLabel(this.tr.getActiveLang(), r.title) + ' ' + '(' + uiLabel(this.tr.getActiveLang(), r.parent?.title) + ')';
        } else {
          info.concatTitle = uiLabel(this.tr.getActiveLang(), r.title) ?? null;
        }
        return info;
      });
    });
  }
}
