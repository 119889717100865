/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PaymentType = 'CASH' | 'POS' | 'FORTE' | 'RAHMET' | 'SENIM' | 'CHECKING_ACCOUNT' | 'KASPI' | 'HALYK' | 'DEBT' | 'KASPI_POS' | 'PAYBOX' | 'UZ_PAYBOX' | 'COIN' | 'SQUAREUP';

export const PaymentType = {
    CASH: 'CASH' as PaymentType,
    POS: 'POS' as PaymentType,
    FORTE: 'FORTE' as PaymentType,
    RAHMET: 'RAHMET' as PaymentType,
    SENIM: 'SENIM' as PaymentType,
    CHECKING_ACCOUNT: 'CHECKING_ACCOUNT' as PaymentType,
    KASPI: 'KASPI' as PaymentType,
    HALYK: 'HALYK' as PaymentType,
    DEBT: 'DEBT' as PaymentType,
    KASPI_POS: 'KASPI_POS' as PaymentType,
    PAYBOX: 'PAYBOX' as PaymentType,
    UZ_PAYBOX: 'UZ_PAYBOX' as PaymentType,
    COIN: 'COIN' as PaymentType,
    SQUAREUP: 'SQUAREUP' as PaymentType
};

