<div class="label__container">
  <nz-tag *ngIf="type === 'input'">{{ label }}</nz-tag>

  <!--nzEllipsis nzExpandable [nzEllipsisRows]="2"-->
  <p *ngIf="type === 'textarea'" nz-typography [nzContent]="label">
  </p>

  <markdown *ngIf="type === 'markdown'" [data]="label"></markdown>

  <button (click)="showEditPanel()" [nzSize]="'small'" type="button" nz-button nzType="primary" nzShape="circle">
    <i nz-icon nzType="edit"></i>
  </button>
</div>

<ng-template #drawerTpl>
  <form nz-form [nzLayout]="'vertical'" [formGroup]="form" (ngSubmit)="closeDrawer()">
    <div *ngFor="let lang of langs" nz-row [nzGutter]="8">
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label>{{lang}}</nz-form-label>
          <nz-form-control>
            <input *ngIf="type === 'input'" nz-input [formControlName]="lang" />
            <textarea *ngIf="type === 'textarea' || type === 'markdown'" rows="4" nz-input [formControlName]="lang"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #drawerFooterTpl>
  <button nz-button nzType="primary" (click)="closeDrawer()">Ок</button>
</ng-template>
