import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { CountryModel, HxStoreService } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'hx-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxCountrySelectComponent),
    multi: true
  }]
})
export class HxCountrySelectComponent implements OnInit, ControlValueAccessor {

  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Output() selectChange = new EventEmitter<CountryModel | CountryModel[] | undefined>();

  options: CountryModel[] = [];
  selected: number | number[] | undefined;

  constructor(
    private storeService: HxStoreService,
  ) {
  }

  ngOnInit(): void {
    this.storeService.getCountries().subscribe(list => {
      this.options = list;
    });
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
