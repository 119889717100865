import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { CityModel, HxStoreService, StoreFullModel, UiLabel } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';

interface StoreGroup {
  cityId: number;
  city: CityModel;
  stores: StoreFullModel[];
}

/**
 * Component search product info by search text
 */
@Component({
  selector: 'hx-store-select',
  templateUrl: './store-select.component.html',
  styleUrls: ['./store-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxStoreSelectComponent),
    multi: true
  }]
})
export class HxStoreSelectComponent implements OnInit, ControlValueAccessor {

  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Input() orgId?: number;
  @Input() brandId?: number;
  @Output() selectChange = new EventEmitter<StoreFullModel | StoreFullModel[] | undefined>();

  optionGroups: { cityTitle: UiLabel, countryTitle: UiLabel, options: StoreFullModel[] }[] = [];
  options: StoreFullModel[] = [];
  private $selected?: number | number[];

  get selected(): number | number[] | undefined {
    return this.$selected;
  }

  set selected(val: number | number[] | undefined) {
    this.$selected = val;
    this.onChange(this.$selected);
  }

  constructor(
    private storeService: HxStoreService,
  ) {
  }

  ngOnInit(): void {
    this.storeService.getFullStores({limit: 1000, orgId: this.orgId, brandId: this.brandId}).then(stores => {
      this.options = stores.list;
      this.optionGroups = stores.list
        .reduce((prev, current) => {
          let cityGroup = prev.find((sg: StoreGroup) => sg.cityId === current.city.id);
          if (!cityGroup) {
            cityGroup = {cityId: current.city.id, city: current.city, stores: []};
            prev.push(cityGroup);
          }
          cityGroup.stores.push(current);
          return prev;
        }, new Array<StoreGroup>())
        .map((sg: StoreGroup) => ({cityTitle: sg.city.title, countryTitle: sg.city.country.title, options: sg.stores}));
    });
  }

  onModelChanged(val: any) {
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
