import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', redirectTo: '/dashboard'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'composite-products',
    loadChildren: () => import('./modules/composite-products/composite-products.module').then(m => m.CompositeProductsModule),
    data: {
      breadcrumb: 'CompositeProducts'
    }
  },
  {
    path: 'product-definitions',
    loadChildren: () => import('./modules/product-definitions/product-definitions.module').then(m => m.ProductDefinitionsModule),
    data: {
      breadcrumb: 'ProductDefinitions'
    }
  },
  {
    path: 'product-categories',
    loadChildren: () => import('./modules/product-categories/product-categories.module').then(m => m.ProductCategoriesModule),
    data: {
      breadcrumb: 'ProductCategories'
    }
  },
  {
    path: 'permissions',
    loadChildren: () => import('./modules/permissions/permissions.module').then(m => m.PermissionsModule),
    data: {
      breadcrumb: 'Permissions'
    }
  },
  {
    path: 'promo-banners',
    loadChildren: () => import('./modules/promo-banners/promo-banners.module').then(m => m.PromoBannersModule),
    data: {
      breadcrumb: 'PromoBanners'
    }
  },
  {
    path: 'organizations',
    loadChildren: () => import('./modules/organizations/organizations.module').then(m => m.OrganizationsModule),
    data: {
      breadcrumb: 'Organizations'
    }
  },
  {
    path: 'stores',
    loadChildren: () => import('./modules/stores/stores.module').then(m => m.StoresModule),
    data: {
      breadcrumb: 'Stores'
    }
  },
  {
    path: 'webkassa-cashboxes',
    loadChildren: () => import('./modules/webkassa-cashboxes/webkassa-cashboxes.module').then(m => m.WebkassaCashboxesModule),
    data: {
      breadcrumb: 'WebkassaCashboxes'
    }
  },
  {
    path: 'cities',
    loadChildren: () => import('./modules/cities/cities.module').then(m => m.CitiesModule),
    data: {
      breadcrumb: 'Cities'
    }
  },
  {
    path: 'brands',
    loadChildren: () => import('./modules/brands/brands.module').then(m => m.BrandsModule),
    data: {
      breadcrumb: 'Brands'
    }
  },
  {
    path: 'delivery-zones',
    loadChildren: () => import('./modules/delivery-zones/delivery-zones.module').then(m => m.DeliveryZonesModule),
    data: {
      breadcrumb: 'DeliveryZones'
    }
  },
  {
    path: 'spend-categories',
    loadChildren: () => import('./modules/spend-categories/spend-categories.module').then(m => m.SpendCategoriesModule),
    data: {
      breadcrumb: 'SpendCategories'
    }
  },
  {
    path: 'workflows',
    loadChildren: () => import('./modules/workflows/workflows.module').then(m => m.WorkflowsModule),
    data: {
      breadcrumb: 'Workflows'
    }
  },
  {
    path: 'operations',
    loadChildren: () => import('./modules/operations/operations.module').then(m => m.OperationsModule),
    data: {
      breadcrumb: 'Operations'
    }
  },
  {
    path: 'faqs',
    loadChildren: () => import('./modules/faqs/faq.module').then(m => m.FaqsModule),
    data: {
      breadcrumb: 'Faqs'
    }
  },
  {
    path: 'dictionaries',
    loadChildren: () => import('./modules/dictionaries/dictionary.module').then(m => m.DictionariesModule),
    data: {
      breadcrumb: 'Dictionaries'
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
