import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { HxOrganizationService, OrganizationModel, OrganizationWithUinModel } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hx-org-select',
  templateUrl: './org-select.component.html',
  styleUrls: ['./org-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxOrgSelectComponent),
    multi: true
  }]
})
export class HxOrgSelectComponent implements OnInit, ControlValueAccessor, OnChanges, OnDestroy {

  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Input() countryId?: number | null;
  @Output() selectChange = new EventEmitter<OrganizationModel | OrganizationModel[] | undefined>();

  options: OrganizationWithUinModel[] = [];
  private _selected?: number | number[];
  private $loadSubject = new Subject<void>();
  private $destroyed = new Subject<void>();

  get selected(): number | number[] | undefined {
    return this._selected;
  }

  set selected(val: number | number[] | undefined) {
    this._selected = val;
    this.onChange(this._selected);
  }

  constructor(
    private orgService: HxOrganizationService,
  ) {
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
    this.$loadSubject.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countryId'] && this.countryId) {
      this.$loadSubject.next();
    }
  }

  ngOnInit(): void {
    this.$loadSubject.pipe(takeUntil(this.$destroyed), debounceTime(300)).subscribe(async () => {
      const {list, count} = await this.orgService.getOrganizations({limit: 1000, countryId: this.countryId ?? undefined});
      this.options = list;

      if (this.selected) {
        if (Array.isArray(this.selected)) {
          const filteredArr = this.selected.filter(orgId => this.options.some(org => org.organization.id === orgId));
          if (filteredArr.length !== this.selected.length) {
            this.selected = filteredArr;
          }
        } else {
          const found = this.options.some(org => org.organization.id === this.selected);
          if (!found) {
            this.selected = undefined;
          }
        }
      }
    });
    this.$loadSubject.next();
  }

  onModelChanged(val: any) {
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.organization.id === this.selected)?.organization);
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.organization.id)).map(p => p.organization));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
