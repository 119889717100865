import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { HxCategoryService, ProductCategoryModel } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

/**
 * Component search product info by search text
 */
@Component({
  selector: 'hx-product-category-select',
  templateUrl: './product-category-select.component.html',
  styleUrls: ['./product-category-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxProductCategorySelectComponent),
    multi: true
  }]
})
export class HxProductCategorySelectComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {

  @Input() brandId?: number;
  @Input() hxMode: NzSelectModeType = 'default';
  @Input() preload = false;
  @Input() includeDisabled = true;
  @Output() selectChange = new EventEmitter<ProductCategoryModel | ProductCategoryModel[] | undefined>();

  options: ProductCategoryModel[] = [];
  isLoading = false;
  disabled = false;
  selected: number | number[] | undefined;
  private $searchChange = new Subject<string>();
  private $destroyed = new Subject<void>();

  constructor(
    private categoryService: HxCategoryService,
  ) {
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngOnInit(): void {
    if (!this.preload) {
      this.$searchChange
        .pipe(
          takeUntil(this.$destroyed),
          filter(query => !!query && query.trim() !== ''),
          debounceTime(500),
          tap(() => this.isLoading = true),
          switchMap(query => this.categoryService.getCategories({
            brandId: this.brandId,
            query: query,
            includeDisabled: this.includeDisabled,
          })),
          tap(() => this.isLoading = false),
          map(items => items.list),
          catchError(() => {
            this.isLoading = false;
            return of([] as ProductCategoryModel[]);
          }),
        )
        .subscribe(list => this.options = list);
    }
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    /*console.log('[HxUserSelect] writeValue called', obj);*/
    if (obj) {
      let ids: number[] = [];
      if (Array.isArray(obj)) {
        ids = ids.concat(obj);
      } else {
        ids.push(obj);
      }
      if (ids.length > 0 && !this.preload) {
        this.categoryService.getCategoriesByIds(ids).subscribe(list => this.options = list);
      }
    }
    this.selected = obj;
  }

  onSearch(query: string) {
    if (!this.preload) {
      this.$searchChange.next(query);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['brandId']) {
      this.options = [];
      if (this.preload && this.brandId) {
        this.categoryService.getCategories({brandId: this.brandId, limit: 1000})
          .subscribe(categories => this.options = categories.list);
      }
    }
  }
}
