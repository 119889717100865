import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HxTagService } from 'hx-services';

/**
 * Component search product info by search text
 */
@Component({
  selector: 'hx-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxTagSelectComponent),
    multi: true
  }]
})
export class HxTagSelectComponent implements OnInit, ControlValueAccessor {

  @Input() hxTagContainer!: string;
  @Input() isDisabled = false;
  @Input() autoSave = true; // if new tag should be persisted to DB
  @Output() selectChange = new EventEmitter<string[] | undefined>();
  options: string[] = [];
  private $selected?: string[];

  get selected(): string[] | undefined {
    return this.$selected;
  }

  set selected(val: string[] | undefined) {
    this.$selected = val;
    this.onChange(this.$selected);
  }

  constructor(
    private tagService: HxTagService,
  ) {
  }

  ngOnInit(): void {
    this.tagService.getTags(this.hxTagContainer).then(tags => this.options = tags);
  }

  onModelChanged(val: string[]) {
    this.tagService.saveTags(this.hxTagContainer, val);
    this.selectChange.emit(this.selected);
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: string[]): void {
    this.selected = obj;
  }
}
