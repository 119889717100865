import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DictionaryKey } from '../enum';
import { DictionaryEntryModel, DictionaryResourceService, SaveDictionaryRequest } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxDictionaryService {
  constructor(
    private dictionaryResource: DictionaryResourceService,
  ) {
  }

  getEntries(params: {
    key: DictionaryKey;
    query?: string;
    page?: number;
    limit?: number;
  }) {
    return firstValueFrom(this.dictionaryResource.getEntryList(params.key, params?.limit, params?.page, params?.query));
  }

  saveDictionary(req: SaveDictionaryRequest) {
    return firstValueFrom(this.dictionaryResource.saveDictionary(req));
  }

  getDictionaryList(params?: {
    query?: string;
    page?: number;
    limit?: number;
  }) {
    return firstValueFrom(this.dictionaryResource.getDictionaryList(params?.limit, params?.page, params?.query));
  }

  getDictionaryById(id: number) {
    return firstValueFrom(this.dictionaryResource.getDictionaryById(id));
  }

  removeById(id: number) {
    return firstValueFrom(this.dictionaryResource.removeDictionaryById(id));
  }

  removeEntryByCode(key: string, code: string) {
    return firstValueFrom(this.dictionaryResource.removeDictionaryEntryByCode(key, code));
  }

  setEntryEnabled(key: string, code: string, value: boolean) {
    return firstValueFrom(this.dictionaryResource.setEntryEnabled(code, key, {value: value}));
  }

  saveEntries(key: string, entries: DictionaryEntryModel[]) {
    return firstValueFrom(this.dictionaryResource.saveDictionaryEntries(key, {entries: entries}));
  }
}
