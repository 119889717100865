import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeRuKZ from '@angular/common/locales/ru-KZ';
import localeRu from '@angular/common/locales/ru';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { SharedModule } from './shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@adm-web-env/environment';
import {
  ApiModule,
  ComponentType,
  Configuration,
  HX_APP_VERSION,
  HX_COMPONENT_NAME,
  HX_ENV_MODE,
  HX_KEYCLOAK,
  HxAuthService,
  initKeycloak,
  TranslocoRootModule
} from 'hx-services';
import { APP_VERSION } from '@adm-web-env/version';
import { enablePatches } from 'immer';
import { TranslocoService } from '@ngneat/transloco';

registerLocaleData(localeRuKZ);
registerLocaleData(localeRu);

enablePatches();

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslocoRootModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ApiModule.forRoot(() => new Configuration({basePath: ''})),
  ],
  providers: [
    {
      provide: HX_COMPONENT_NAME,
      useValue: ComponentType.adm
    },
    {
      provide: HX_ENV_MODE,
      useValue: environment.production
    },
    {
      provide: HX_APP_VERSION,
      useValue: APP_VERSION
    },
    {
      provide: HX_KEYCLOAK,
      useValue: {
        url: environment.keycloakUrl,
        clientId: environment.keycloakClientId,
        realm: environment.keycloakRealm
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initKeycloak,
      multi: true,
      deps: [KeycloakService, HxAuthService, HX_KEYCLOAK, TranslocoService],
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
