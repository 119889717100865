<nz-select [(ngModel)]="selected" (ngModelChange)="onModelChanged($event)" [nzDisabled]="disabled || !brandId" [nzMode]="hxMode"
           nzPlaceHolder="Выберите категории"
           nzAllowClear [nzDropdownMatchSelectWidth]="false"
           nzServerSearch
           (nzOnSearch)="onSearch($event)"
           nzShowSearch>
  <ng-container *ngIf="!isLoading">
    <nz-option *ngFor="let op of options"
               [nzDisabled]="!op.enabled"
               [nzValue]="op.id" [nzLabel]="op.title | uiLabel"></nz-option>
  </ng-container>
  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
    <i nz-icon nzType="loading" class="loading-icon"></i> Поиск...
  </nz-option>
</nz-select>
