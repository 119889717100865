import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AppEventFull,
  AuthorityModel,
  CalculateResponse,
  CategoryProduct,
  CityRemainderProductModel,
  CompanyModel,
  DiscountsResponse,
  OrderAllResponse,
  OrderEvent,
  OrderExpiration,
  OrderResponse,
  PagedList,
  PickupTimeCount,
  PromoCodeModel,
  ReceiptModel,
  RemainderProductModel,
  TrackingModel
} from '../interface';
import { firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { toHttpParams } from '../utils/globals';
import {
  AtolFiscalResult, CommentModel, AddCommentRequest,
  ConfectioneryResourceService,
  DeliveryType,
  DiscountResultModel,
  Entity,
  OrderAction,
  OrderResourceService,
  PaymentType, ProductDateCountInfoModel, ProductResourceService,
  ProductShortModel,
  SeekDirection,
  SortType,
  SourceSystem,
  UiLabel,
  WebkassaFiscalResult
} from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxOrderService {
  private printListSubject = new Subject<ReceiptModel[]>();
  private updateOrderSubject = new Subject<void>();
  private receiveAfterTimeChangeSubject = new ReplaySubject<OrderEvent<ReceiveAfterTime>>(1);
  private recalcDiscountsSubject = new Subject<void>();

  readonly printListCheckObs = this.printListSubject.asObservable();
  readonly updateOrderObs = this.updateOrderSubject.asObservable();
  readonly receiveAfterTimeChangeObs = this.receiveAfterTimeChangeSubject.asObservable();
  readonly recalcDiscountsObs = this.recalcDiscountsSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private confectioneryResourceService: ConfectioneryResourceService,
    private orderResource: OrderResourceService,
    private productResource: ProductResourceService
  ) {
  }

  notifyReceiveAfterTimeChanged(orderId: number, event: ReceiveAfterTime) {
    this.receiveAfterTimeChangeSubject.next({id: orderId, event: event});
  }

  printCheck(check: ReceiptModel): void {
    this.printListSubject.next([check]);
  }

  printCheckList(list: ReceiptModel[]): void {
    this.printListSubject.next(list);
  }

  orderUpdated() {
    this.updateOrderSubject.next();
  }

  recalcDiscounts() {
    this.recalcDiscountsSubject.next();
  }

  getReservedOrders(date: string, storeId: number): Observable<PickupTimeCount[]> {
    return this.httpClient.get<PickupTimeCount[]>(`/api/vanilla/orders/reserved-order-count?date=${date}&storeId=${storeId}`);
  }

  getRemainders(params: { storeIds?: number[], date: string, categoryIds: number[] }): Promise<RemainderProductModel[]> {
    const queryParams = toHttpParams(params, true);

    return firstValueFrom(this.httpClient.get<RemainderProductModel[]>(`/api/vanilla/orders/remainder-products`, {params: queryParams}));
  }

  getRemaindersByCityId(cityId: number, params: { date: string; categoryIds: number[]; brandId: number; }): Promise<CityRemainderProductModel[]> {
    const queryParams = toHttpParams(params, true);
    return firstValueFrom(this.httpClient.get<CityRemainderProductModel[]>(`/api/vanilla/orders/cities/${cityId}/remainder-products`, {params: queryParams}));
  }

  generateInvoice(orderId: number, type: 'product' | 'service' | 'payment'): Observable<any> {
    return this.httpClient.get(`/api/vanilla/orders/${orderId}/invoice?type=${type}`,
      {responseType: 'arraybuffer', observe: 'response'});
  }

  getAppEventById(id: number): Observable<AppEventFull> {
    return this.httpClient.get<AppEventFull>(`/api/vanilla/events/${id}`);
  }

  getOrderReceipt(params: { orderId: number, eventId?: number }): Observable<ReceiptModel> {
    let queryParams = new HttpParams();
    if (params.eventId) {
      queryParams = queryParams.append('eventId', params.eventId + '');
    }
    return this.httpClient.get<ReceiptModel>(`/api/vanilla/orders/${params.orderId}/receipt`, {params: queryParams});
  }

  saveFiscalizationData(orderId: number, fiscalDetails: AtolFiscalResult | WebkassaFiscalResult): Observable<void> {
    return this.httpClient.post<void>(`/api/vanilla/orders/${orderId}/fiscal-details`, fiscalDetails);
  }

  getOrderReceipts(body: { orderIds: number[] }): Observable<ReceiptModel[]> {
    return this.httpClient.post<ReceiptModel[]>(`/api/vanilla/orders/receipt`, body);
  }

  getOrderById(id: number): Promise<OrderResponse> {
    return firstValueFrom(this.httpClient.get<OrderResponse>(`/api/vanilla/orders/${id}`));
  }

  getFullOrderById(id: number): Promise<OrderResponse> {
    return firstValueFrom(this.httpClient.get<OrderResponse>(`/api/vanilla/orders/${id}/full`));
  }

  getTracking(id: number): Promise<TrackingModel> {
    return firstValueFrom(this.httpClient.get<TrackingModel>(`/api/vanilla/orders/${id}/tracking`));
  }

  getCompanyInfo(id: number): Observable<{ company: CompanyModel, authority: AuthorityModel }> {
    return this.httpClient.get<{ company: CompanyModel, authority: AuthorityModel }>(`/api/vanilla/orders/${id}/company`);
  }

  saveCompanyInfo(orderId: number, req: CompanyModel, params: { clientId?: number }): Observable<void> {
    return this.httpClient.post<void>(`/api/vanilla/orders/${orderId}/company`, req, {params: toHttpParams(params, true)});
  }

  saveAuthorityInfo(orderId: number, req: AuthorityModel): Observable<void> {
    return this.httpClient.post<void>(`/api/vanilla/orders/${orderId}/company-authority`, req);
  }

  getOrderList(params?: {
    limit?: number,
    seek?: string,
    seekDir?: SeekDirection,
    sort?: SortType,
    orderBy?: 'id' | 'number' | 'date',
    query?: string,
    clientId?: number,
    cityId?: number,
    storeIds?: number[],
    actions?: OrderAction[],
    deliveryType?: DeliveryType,
    clientType?: Entity,
    date?: string, // iso date
    fromDate?: string,
    toDate?: string,
    decor?: 'INSCRIPTION' | 'DECORED',
    courierId?: number,
    decored?: boolean,
    archived?: boolean,
    excludeChildren?: boolean,
    includeWaitPayment?: boolean,
    activeCart?: boolean,
    fiscalNumber?: string,
    sourceSystem?: string,
    important?: boolean,
    operatorId?: number,
    productInfoIds?: number[],
  }) {
    return firstValueFrom(this.orderResource.getOrderList(
      params?.actions,
      params?.activeCart,
      params?.archived,
      params?.cityId,
      params?.clientId,
      params?.clientType,
      params?.courierId,
      params?.date,
      params?.decor,
      params?.decored,
      params?.deliveryType,
      params?.excludeChildren,
      params?.fiscalNumber,
      params?.fromDate,
      params?.important,
      params?.includeWaitPayment,
      params?.limit,
      params?.operatorId,
      params?.orderBy,
      params?.productInfoIds,
      params?.query,
      params?.seek,
      params?.seekDir,
      params?.sort,
      params?.sourceSystem,
      params?.storeIds,
      params?.toDate
    ));
  }

  getOrderListByProductInfoId(id: number, params?: {
    storeIds?: number[];
    limit?: number;
    page?: number;
    sort?: SortType;
    orderBy?: string;
    date?: string; // iso date
    actions?: OrderAction[]
  }): Observable<PagedList<OrderAllResponse>> {
    return this.httpClient.get<PagedList<OrderAllResponse>>(`/api/vanilla/orders/products/${id}`, {params: toHttpParams(params, true)});
  }

  createOrder(request: CreateCartRequest): Promise<OrderResponse> {
    return firstValueFrom(this.httpClient.post<OrderResponse>(`/api/vanilla/orders`, request));
  }

  updateOrder(id: number, body: UpdateOrderRequest): Promise<UpdateOrderResponse> {
    return firstValueFrom(this.httpClient.put<UpdateOrderResponse>(`/api/vanilla/orders/${id}`, body));
  }

  transferOrder(id: number, body: TransferOrderRequest): Promise<TransferOrderResponse> {
    return firstValueFrom(this.httpClient.post<TransferOrderResponse>(`/api/vanilla/orders/${id}/transfer`, body));
  }

  cancelOrder(id: number, body: any): Observable<void> {
    return this.httpClient.put<void>(`/api/vanilla/orders/${id}/cancel`, body);
  }

  // getOrderByNumber(num: string): Observable<OrderFullModel[]> {
  //   return this.httpClient.get<OrderFullModel[]>(`/api/vanilla/orders/numbers/${num}`);
  // }

  getOrderCartById(id: number): Promise<OrderResponse> {
    return firstValueFrom(this.httpClient.get<OrderResponse>(`/api/vanilla/orders/${id}/cart`));
  }

  getActiveOrderCart(): Observable<OrderResponse> {
    return this.httpClient.get<OrderResponse>(`/api/vanilla/orders/cart/active`);
  }

  getCartExpiration(orderId: number): Observable<OrderExpiration> {
    return this.httpClient.get<OrderExpiration>(`/api/vanilla/orders/${orderId}/cart-expiration`);
  }

  prolongCartExpiration(orderId: number): Observable<OrderExpiration> {
    return this.httpClient.put<OrderExpiration>(`/api/vanilla/orders/${orderId}/cart-expiration`, null);
  }

  cancelCart(orderId: number): Promise<void> {
    return firstValueFrom(this.httpClient.delete<void>(`/api/vanilla/orders/${orderId}/cart`));
  }

  getCartProducts(orderId: number): Observable<ProductShortModel[]> {
    return this.httpClient.get<ProductShortModel[]>(`/api/vanilla/orders/${orderId}/cart/products`);
  }

  addCartProduct(orderId: number, params: { priceId: number }): Promise<ProductShortModel> {
    return firstValueFrom(this.httpClient.post<ProductShortModel>(`/api/vanilla/orders/${orderId}/cart/products`, null, {params: toHttpParams(params, true)}));
  }

  removeCartProduct(orderId: number, productId: number): Observable<void> {
    return this.httpClient.delete<void>(`/api/vanilla/orders/${orderId}/cart/products/${productId}`);
  }

  setImportant(orderId: number, important: boolean): Observable<void> {
    return this.httpClient.patch<void>(`/api/vanilla/orders/${orderId}/important`, {value: important});
  }

  downCartProduct(orderId: number, productId: number): Observable<void> {
    return this.httpClient.delete<void>(`/api/vanilla/orders/${orderId}/cart/products/${productId}/down`);
  }

  checkPaymentStatus(params: { orderId: number, type: PaymentType }): Observable<{ paid: boolean }> {
    return this.httpClient.get<{ paid: boolean }>(`/api/vanilla/orders/${params.orderId}/payment-status`);
  }

  /*createOrderCheckModal(orderId: number): Promise<ReceiptModel> {
    const modalInstance = this.modal.open(HxOrderReceiptComponent, {windowClass: 'modal--check'});
    modalInstance.componentInstance.checks = [{orderId: orderId}];
    modalInstance.componentInstance.isView = true;
    return modalInstance.result;
  }

  createOrdersCheckModal(receipts: ReceiptModel[]): Promise<ReceiptModel> {
    const checks: ReceiptModel[] = receipts.map(r => {
      return {
        orderId: r.orderId,
        qr: r.qr
      } as ReceiptModel;
    });
    const modalInstance = this.modal.open(HxOrderReceiptComponent, {windowClass: 'modal--check'});
    modalInstance.componentInstance.checks = checks;
    modalInstance.componentInstance.isView = true;
    return modalInstance.result;
  }*/

  isOrderArchived(orderId: number): Observable<boolean> {
    return this.httpClient.get<{ result: boolean }>(`/api/vanilla/orders/${orderId}/archived`).pipe(map(response => response.result));
  }

  unarchive(orderId: number): Observable<any> {
    return this.httpClient.post(`/api/vanilla/orders/${orderId}/unarchived`, {});
  }

  startOrderModification(orderId: number): Observable<void> {
    return this.httpClient.post<void>(`/api/vanilla/orders/${orderId}/modifying`, undefined);
  }

  getChildOrders(orderId: number): Promise<OrderResponse[]> {
    return firstValueFrom(this.httpClient.get<OrderResponse[]>(`/api/vanilla/orders/${orderId}/children`));
  }

  getReceiveAfterTime(orderId: number, storeId?: number): Promise<ReceiveAfterTime> {
    return firstValueFrom(this.httpClient.get<ReceiveAfterTime>(`/api/vanilla/orders/${orderId}/receive-after-time`, {params: toHttpParams({storeId: storeId}, true)}));
  }

  reservePickupTime(param: { pickupTime: string, orderId: number }): Promise<void> {
    return firstValueFrom(this.httpClient.patch<void>(`/api/vanilla/orders/${param.orderId}/pickup-time`, {time: param.pickupTime}));
  }

  checkPaymentStatusByEventId(params: { orderId: number, eventId: number }): Observable<{ paid: boolean }> {
    return this.httpClient.post<{ paid: boolean }>(`/api/vanilla/orders/${params.orderId}/events/${params.eventId}/refresh-payment-status`, undefined);
  }

  checkDiscount(body: CalculateDiscountRequest): Observable<CalculateResponse> {
    return this.httpClient.post<CalculateResponse>(`/api/vanilla/orders/discounts`, body);
  }

  getDiscountList(orderId: number): Promise<DiscountsResponse> {
    return firstValueFrom(this.httpClient.get<DiscountsResponse>(`/api/vanilla/orders/${orderId}/discounts`));
  }

  sendOtpCode(params: { orderId: number, clientId: number }): Observable<void> {
    const reqOpts = {params: toHttpParams({clientId: params.clientId}, true)};
    return this.httpClient.post<void>(`/api/vanilla/orders/${params.orderId}/otp-code`, {}, reqOpts);
  }

  confirmOtpCode(params: { orderId: number, clientId: number, code: string }): Observable<any> {
    const reqOpts = {params: toHttpParams({clientId: params.clientId, code: params.code}, true)};
    return this.httpClient.post(`/api/vanilla/orders/${params.orderId}/otp-code/confirmation`, {}, reqOpts);
  }

  getPromoCode(orderId: number): Observable<PromoCodeModel | undefined> {
    return this.httpClient.get<PromoCodeModel | undefined>(`/api/vanilla/orders/${orderId}/promo-code`)
      .pipe(map(r => r === null ? undefined : r));
  }

  applyPromoCode(orderId: number, code: string): Observable<{ promoCode: PromoCodeModel, priority: number }> {
    return this.httpClient.post<{ promoCode: PromoCodeModel, priority: number }>(`/api/vanilla/orders/${orderId}/promo-code`, {code: code});
  }

  confirmPromoCode(id: number, confirmCode: string): Observable<PromoCodeModel> {
    return this.httpClient.post<PromoCodeModel>(`/api/vanilla/orders/promo-codes/${id}/confirmed`, {confirmCode: confirmCode});
  }

  cancelPromoCode(orderId: number): Observable<void> {
    return this.httpClient.delete<void>(`/api/vanilla/orders/${orderId}/promo-code`);
  }

  sendPayInvoice(orderId: number, body: {
    paymentType: PaymentType;
    phone: string;
  }): Promise<{ total: number }> {
    return firstValueFrom(this.orderResource.sendPayPushNotification(orderId, {
      paymentType: body.paymentType,
      phone: body.phone,
    }));
  }

  getProductCountsByCategoryAndDate(params: { fromDate?: string, toDate?: string, storeIds?: number[] }): Promise<ProductDateCountInfoModel[]> {
    return firstValueFrom(this.productResource.getProductCountsByCategoryAndDate(params.fromDate, params.storeIds, params.toDate));
  }

  updateOrderDeliveryLatAndLong(orderId: number, body: { deliveryLat: number, deliveryLong: number }): Observable<void> {
    return this.httpClient.post<void>(`/api/vanilla/orders/${orderId}/lat-long`, body);
  }

  decorOrder(orderId: number): Promise<void> {
    return firstValueFrom(this.confectioneryResourceService.decorOrder(orderId));
  }

  updateApplyCoins(orderId: number, value: boolean) {
    return firstValueFrom(this.orderResource.updateApplyCoins(orderId, value));
  }

  getCommentsByOrderId(orderId: number): Promise<CommentModel[]> {
    return firstValueFrom(this.orderResource.getCommentsByOrderId(orderId));
  }

  addComment(orderId: number, request: AddCommentRequest): Promise<CommentModel> {
    return firstValueFrom(this.orderResource.saveComment(orderId, request));
  }
}

export interface ReceiveAfterTime {
  productTitle: UiLabel;
  time: string;
}

export interface TransferOrderRequest {
  orderDate: string;
  orderTime: string;
  clientId: number;
  clientPhone: string;
  storeId: number;
  deliveryType: DeliveryType;
  address?: string; // required if deliveryType = DELIVERY
  deliveryLongitude?: number;
  deliveryLatitude?: number;
  rangeId?: number;
  total: number;
  discounts: DiscountResultModel[];
  entrance?: string;
  flat?: string;
  floor?: string;
}

export interface TransferOrderResponse {
  uniqueNumber: string;
  orderId: number;
}

export interface UpdateOrderRequest {
  orderDate: string;
  orderTime: string;
  clientId: number;
  clientPhone: string;
  clientType: Entity;
  storeId: number;
  deliveryType: DeliveryType;
  discounts: DiscountResultModel[];
  total: number;
  subTotal: number;
  products: Product[];
  recipientClientId?: number;
  recipientClientPhone?: string;
  from?: SourceSystem;
  note?: string;
  decorText?: string;
  debt?: boolean;
  cancelReason?: string;
  important?: boolean;
  address?: string; // required if deliveryType = DELIVERY
  admDiv?: string;
  entrance?: string;
  floor?: string;
  flat?: string;
  deliveryLongitude?: number;
  deliveryLatitude?: number;
  addressNote?: string;
  files?: number[];
  cartRangeId?: number;
  rejectionId?: number;
  referrer?: string;
  withoutReceipt?: boolean;
  comment?: string;
}

export interface UpdateOrderResponse {
  number: number;
  uniqueNumber: string;
}

export interface CreateCartRequest {
  callId?: string;
  date: string; // ISO date
  storeId: number;
  phone?: string;
  rangeId?: number;
  address?: {
    address: string;
    latitude?: number;
    longitude?: number;
    entrance?: string;
    floor?: string;
    flat?: string;
    addressNote?: string;
  };
  products: Product[];
}

export interface CartChangeEvent {
  isDebt: boolean;
  products: ProductShortModel[];
  discounts: DiscountResultModel[];
  total: number;
  subTotal: number;
}

export interface CalculateDiscountRequest {
  results: DiscountResultModel[];
  order: {
    id?: number;
    storeId: number;
    date: string;
    time: string;
    employeeId?: number;
    clientId?: number;
    clientType: Entity;
    deliveryType: DeliveryType;
  };
  products: CalculateDiscountProduct[];
  sourceSystem?: SourceSystem;
}

interface Product {
  productDefinitionId: number;
  amount: number;
  priceAmount: number;
}

interface CalculateDiscountProduct {
  priceId: number;
  amount: number;
}
