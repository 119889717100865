
<nz-tree-select *ngIf="nodes.length > 0"
  style="width: 500px"
  [nzNodes]="nodes"
  nzShowSearch
  nzVirtualHeight="300px"
  nzPlaceHolder="Выберите категорию"
  [nzExpandedKeys]="expandKeys"
  [(ngModel)]="selected"
  (ngModelChange)="onChange($event)"
></nz-tree-select>



