// core
import { Pipe, PipeTransform } from '@angular/core';
import { RecipeType } from '../enum';

@Pipe({
  name: 'recipeType'
})
export class HxRecipeTypePipe implements PipeTransform {

  transform(value: string): string | undefined {
    if (!value) {
      return;
    }
    let newValue;
    if (value === RecipeType.INPUT_PRODUCT) {
      newValue = 'Вход';
    } else if (value === RecipeType.OUTPUT_PRODUCT) {
      newValue = 'Выход';
    }

    return newValue;
  }

}
