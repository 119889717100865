import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { HxCategoryService, WorkflowCategoryModel } from 'hx-services';

@Component({
  selector: 'hx-workflow-category-select',
  templateUrl: './workflow-category-select.component.html',
  styleUrls: ['./workflow-category-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxWorkflowCategorySelectComponent),
    multi: true
  }]
})
export class HxWorkflowCategorySelectComponent implements OnInit, ControlValueAccessor {

  @Input() hxMode: NzSelectModeType = 'default';
  @Output() selectChange = new EventEmitter<WorkflowCategoryModel | WorkflowCategoryModel[] | undefined>();

  disabled = false;
  options: WorkflowCategoryModel[] = [];
  selected: number | number[] | undefined;

  constructor(
    private categoryService: HxCategoryService,
  ) {
  }

  ngOnInit(): void {
    this.categoryService.getWorkflowCategories({limit: 1000}).subscribe(wc => {
      this.options = wc.list;
    });
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
