<nz-select [(ngModel)]="selected" (ngModelChange)="onModelChanged($event)" [nzDisabled]="isDisabled" [nzMode]="hxMode"
           nzPlaceHolder="Выберите магазин" [nzDropdownMatchSelectWidth]="false" nzAllowClear nzShowSearch>
  <ng-container *ngIf="orgId">
    <ng-container *ngFor="let op of options">
      <nz-option *ngIf="op.organizationId === orgId" [nzLabel]="op.title | uiLabel" [nzValue]="op.id"></nz-option>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!orgId">
    <nz-option-group *ngFor="let opg of optionGroups" [nzLabel]="opg.cityTitle | uiLabel">
      <nz-option *ngFor="let op of opg.options" [nzLabel]="op.title | uiLabel" [nzValue]="op.id"></nz-option>
    </nz-option-group>
  </ng-container>
</nz-select>
