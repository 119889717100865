import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { CountryModel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxCountryService {
  constructor(
    private http: HttpClient,
  ) {
  }

  getCountries(): Promise<CountryModel[]> {
    return firstValueFrom(this.http.get<CountryModel[]>(`/api/vanilla/countries`));
  }

  getCountryById(id: number): Promise<CountryModel> {
    return firstValueFrom(this.http.get<CountryModel>(`/api/vanilla/countries/${id}`));
  }

  getCountryByCityId(id: number): Observable<CountryModel> {
    return this.http.get<CountryModel>(`/api/vanilla/countries/city/${id}`);
  }

  getMyCountries(): Observable<CountryModel[]> {
    return this.http.get<CountryModel[]>(`/api/vanilla/countries/my`);
  }
}
