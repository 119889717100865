import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { CityReservationModel, PagedList, StoreProductModel } from '../interface';
import { toHttpParams } from '../utils/globals';
import { ProductInfoType, SortType } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxStoreProductService {

  constructor(private http: HttpClient) {
  }

  getStoreProductList(params?: {
    ids?: number[],
    page?: number,
    limit?: number,
    orderBy?: string,
    sort?: SortType,
    query?: string,
    storeId?: number,
    types?: ProductInfoType[],
    cfOutputProduct?: boolean,
    purchasable?: boolean,
    categoryId?: number
  }): Observable<PagedList<StoreProductModel>> {
    return this.http.get<PagedList<StoreProductModel>>(`/api/vanilla/store-products`, {params: toHttpParams(params, true)});
  }

  getStoreProduct(productId: number): Observable<StoreProductModel> {
    return this.http.get<StoreProductModel>(`/api/vanilla/store-products/${productId}`);
  }

  getStoreProductGroupedByStoreId(productInfoId: number): Observable<CityReservationModel[]> {
    return this.http.get<CityReservationModel[]>(`/api/vanilla/store-products/grouped-by-store-with-reservations`,
      {params: toHttpParams({productInfoId: productInfoId}, true)});
  }

  saveStoreProduct(request: SaveProductRequest): Promise<void> {
    return firstValueFrom(this.http.post<void>(`/api/vanilla/store-products`, request));
  }

  syncStoreProduct(req: {productInfoId: number, storeId: number}): Observable<void> {
    return this.http.put<void>(`/api/vanilla/store-products/sync`, req);
  }

  getBalance(params: {
    storeId?: number,
    categoryIds?: number[],
    productInfoTitle?: string,
    productInfoTypes?: ProductInfoType[]
  }): Observable<StoreProductModel[]> {
    return this.http.get<StoreProductModel[]>(`/api/vanilla/store-products/stores`, {params: toHttpParams(params, true)});
  }
}

export interface SaveProductRequest {
  id: number;
  productInfoId: number;
  storeId: number;
  organizationId: number;
  amount: number;
  forSale: boolean;
  onlineSellable: boolean;
  minOrderDay: number;
  untilOrderTime: string;
  receiveAfterTime: string;
}
