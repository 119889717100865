import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { BankModel, HxBankService } from 'hx-services';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

/**
 * Component search city
 */
@Component({
  selector: 'hx-bank-select',
  templateUrl: './bank-select.component.html',
  styleUrls: ['./bank-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxBankSelectComponent),
    multi: true
  }]
})
export class HxBankSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Output() selectChange = new EventEmitter<BankModel | BankModel[] | undefined>();
  isLoading = false;
  private $searchChange = new Subject<string>();
  private $destroyed = new Subject<void>();

  options: BankModel[] = [];
  selected: number | number[] | undefined;

  constructor(
    private bankService: HxBankService,
  ) {
  }

  ngOnInit(): void {
    this.$searchChange
      .pipe(
        takeUntil(this.$destroyed),
        filter(query => !!query && query.trim() !== ''),
        debounceTime(500),
        switchMap(query => {
          this.isLoading = true;
          return this.bankService.getBankList({
            query: query,
            limit: 1000
          });
        }),
        tap(() => this.isLoading = false),
        map(items => items.list),
      )
      .subscribe(bank => {
        this.isLoading = false;
        this.options = bank;
      });
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  search(query: string) {
    this.$searchChange.next(query);
  }

  onModelChanged(val: any) {
    this.onChange(val);
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    if (obj) {
      let ids: number[] = [];
      if (Array.isArray(obj)) {
        ids = ids.concat(obj);
      } else {
        ids.push(obj);
      }
      if (ids.length > 0) {
        this.bankService.getBankByIds(ids).subscribe(pi => this.options = pi);
      }
    }
    this.selected = obj;
  }
}
