<nz-select [(ngModel)]="selected" (ngModelChange)="onModelChanged($event)" [nzDisabled]="isDisabled" [nzMode]="hxMode"
           nzPlaceHolder="Выберите пользователя"
           nzAllowClear  [nzDropdownMatchSelectWidth]="false"
           nzServerSearch
           (nzOnSearch)="onSearch($event)"
           nzShowSearch>
  <ng-container *ngIf="!isLoading">
    <nz-option *ngFor="let op of options"
               [nzDisabled]="!op.active"
               [nzValue]="op.id" [nzLabel]="op.fullname"></nz-option>
  </ng-container>
  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
    <i nz-icon nzType="loading" class="loading-icon"></i> Поиск...
  </nz-option>
</nz-select>
