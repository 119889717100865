import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { CityModel, CountryModel, HxCityService, UiLabel } from 'hx-services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';

interface CityGroup {
  countryId: number;
  country: CountryModel;
  cities: CityModel[];
}

/**
 * Component search city
 */
@Component({
  selector: 'hx-city-select',
  templateUrl: './city-select.component.html',
  styleUrls: ['./city-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxCitySelectComponent),
    multi: true
  }]
})
export class HxCitySelectComponent implements OnInit, ControlValueAccessor {

  @Input() hxMode: NzSelectModeType = 'default';
  @Input() isDisabled = false;
  @Output() selectChange = new EventEmitter<CityModel | CityModel[] | undefined>();

  optionGroups: { countryTitle: UiLabel, options: CityModel[] }[] = [];
  private $selected?: number | number[];
  private options: CityModel[] = [];

  get selected(): number | number[] | undefined {
    return this.$selected;
  }

  set selected(val: number | number[] | undefined) {
    this.$selected = val;
    this.onChange(this.$selected);
  }

  constructor(
    private cityService: HxCityService,
  ) {
  }

  ngOnInit(): void {
    this.cityService.getAllCities().then(cities => {
      this.options = cities;
      this.optionGroups = cities
        .reduce((prev, current) => {
          let cityGroup = prev.find((sg: CityGroup) => sg.countryId === current.country.id);
          if (!cityGroup) {
            cityGroup = {countryId: current.country.id, country: current.country, cities: []};
            prev.push(cityGroup);
          }
          cityGroup.cities.push(current);
          return prev;
        }, new Array<CityGroup>())
        .map((sg: CityGroup) => ({countryTitle: sg.country.title, options: sg.cities}));
    });
  }

  onModelChanged(val: any) {
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p.id === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: number[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p.id)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: number | number[]): void {
    this.selected = obj;
  }
}
