import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select';

@Component({
  selector: 'hx-enum-select',
  templateUrl: './enum-select.component.html',
  styleUrls: ['./enum-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HxEnumSelectComponent),
    multi: true
  }]
})
export class HxEnumSelectComponent implements OnInit, ControlValueAccessor {

  @Input() hxMode: NzSelectModeType = 'default';
  @Input() enumType!: any;
  @Input() enumName!: string;
  @Input() isDisabled = false;
  @Output() selectChange = new EventEmitter<string | string[] | undefined>();

  options: string[] = [];
  private $selected?: string | string[];

  get selected(): string | string[] | undefined {
    return this.$selected;
  }

  set selected(val: string | string[] | undefined) {
    this.$selected = val;
    this.onChange(this.$selected);
  }

  ngOnInit(): void {
    this.options = Object.keys(this.enumType).map((pt) => this.enumType[pt]);
  }

  onModelChanged(val: any) {
    if (this.hxMode === 'default') {
      if (this.selected) {
        this.selectChange.emit(this.options.find(p => p === this.selected));
      } else {
        this.selectChange.emit();
      }
    } else {
      if (Array.isArray(this.selected)) {
        const ids: string[] = this.selected;
        this.selectChange.emit(this.options.filter(p => ids.includes(p)));
      } else {
        this.selectChange.emit([]);
      }
    }
  }

  onChange(val: any) {
    // console.log('onChange called', val);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj?: string | string[]): void {
    this.selected = obj;
  }
}
