/**
 * Vanilla API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProductInfoType = 'SERVICE' | 'PROPERTY' | 'PRODUCT' | 'INGREDIENT' | 'DELIVERY' | 'MATERIAL_ASSETS' | 'NON_MATERIAL_ASSETS';

export const ProductInfoType = {
    SERVICE: 'SERVICE' as ProductInfoType,
    PROPERTY: 'PROPERTY' as ProductInfoType,
    PRODUCT: 'PRODUCT' as ProductInfoType,
    INGREDIENT: 'INGREDIENT' as ProductInfoType,
    DELIVERY: 'DELIVERY' as ProductInfoType,
    MATERIAL_ASSETS: 'MATERIAL_ASSETS' as ProductInfoType,
    NON_MATERIAL_ASSETS: 'NON_MATERIAL_ASSETS' as ProductInfoType
};

