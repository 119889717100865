import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Observable } from 'rxjs';
import { ConfectionaryProductModel, DateRange, PagedList, ProductDiscardInfoModel } from '../interface';
import { toHttpParams } from '../utils/globals';
import { PriceShortModel } from '../vn-api';

@Injectable({
  providedIn: 'root'
})
export class HxProductService {

  constructor(private httpClient: HttpClient) { }

  getProductDiscards(params: {date?: DateRange, storeId?: number, userId?: number, productInfoId?: number, groupByDate?: boolean}): Observable<ProductDiscardInfoModel[]> {
    return this.httpClient.get<ProductDiscardInfoModel[]>('/api/vanilla/products/discards', {params: toHttpParams(params, true)});
  }

  getPrices(params?: {
    productInfoIds?: number[],
    date?: string,
    storeId?: number,
  }): Promise<PriceShortModel[]> {
    return firstValueFrom(this.httpClient.get<PriceShortModel[]>('/api/vanilla/products/prices', {params: toHttpParams(params, true)}));
  }

  getPriceById(id: number): Promise<PriceShortModel> {
    return firstValueFrom(this.httpClient.get<PriceShortModel>(`/api/vanilla/products/prices/${id}`));
  }

  getDeliveryPrices(params?: {
    date?: string,
    storeId?: number,
  }): Promise<PriceShortModel[]> {
    return firstValueFrom(this.httpClient.get<PriceShortModel[]>('/api/vanilla/products/delivery-prices', {params: toHttpParams(params, true)}));
  }

  getCfProductList(params?: {
    storeId?: number;
    date?: DateRange;
    query?: string;
    userId?: number;
    page?: number;
    limit?: number;
  }): Observable<PagedList<ConfectionaryProductModel>> {
    return this.httpClient.get<PagedList<ConfectionaryProductModel>>(`/api/vanilla/cf/products`, { params: toHttpParams(params, true) });
  }

  reserveProduct(req: { productInfoId: number }): Observable<{ id: number }> {
    return this.httpClient.post<{ id: number }>(`/api/vanilla/products/reserve`, req);
  }
}
