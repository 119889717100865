import { Component, OnInit } from '@angular/core';
import { HxAuthService } from 'hx-services';
import { AppService } from './app.service';
import { environment } from '@adm-web-env/environment';
import { TranslocoService } from '@ngneat/transloco';
import { ResolveEnd, ResolveStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  isCollapsed = false;
  theme: 'light' | 'dark' = 'light'; // dark
  hasAppUpdate = false;
  isProd = environment.production;
  loading = false;

  constructor(
    public auth: HxAuthService,
    private appService: AppService,
    private tr: TranslocoService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.tr.events$.subscribe(event => {
      console.log('[i18n] transloco event', event);
    });
    this.appService.startUpdateChecker();
    this.appService.update.subscribe(() => this.hasAppUpdate = true);
    this.router.events.subscribe(event => {
      if (event instanceof ResolveStart) {
        this.loading = true;
      } else if (event instanceof ResolveEnd) {
        this.loading = false;
      }
    });
  }

  updateApp() {
    this.appService.updateApp();
  }

  checkForUpdate() {
    this.appService.checkUpdates();
  }

  aboutApp() {
    this.appService.about();
  }
}
